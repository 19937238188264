
<div class="menupart">
    <div class="row  mr-0 d-none d-xl-block">
        <div class="col-md-9 col-9">
            <div class="logonav position-relative" [routerLink]="['/superAdmin/mydashboard']"><img
                    src="../../../assets/images/logobig.png" class="logobig" width="220" alt="Radha Swami">
                <div class="logosmall position-absolute top-0 w-100">
                    <img src="../assets/images/svg/logoIcon.svg" width="37" alt="">
                </div>
            </div>
        </div>
        <!-- <div class="col-md-3 col-3 my-auto">
            <div class="closemenum ms-3"><img src="../../../assets/images/icons/close.svg"></div>
        </div> -->
    </div>

    <div class="navigation">
        <ul>
            <li [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']">
                <div id="dash" [routerLink]="['/superAdmin/mydashboard']" class="menuarea">
                    <img src="../assets/images/icons/dashboard.png" alt="dashboard"
                        srcset="../assets/images/icons/dashboard.png">
                    <p>Dashboard</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/mydashboard']">
                    <div class="arrowbox"></div>
                    <h6>Dashboard</h6>


                </div>
            </li>


            <li  *ngIf="isRole3 && isRole1 && isRole2" [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['../superAdmin/loadingslips']">
                    <img src="../assets/images/icons/Loadingslipb.png" alt="loadingslip"
                        srcset="../assets/images/icons/LoadingSlipW.png">
                    <p>Loading Slip</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['../superAdmin/loadingslips']">
                    <div class="arrowbox"></div>
                    <h6>Loading Slip</h6>
                </div>
            </li>

            <li *ngIf="isRole3 && isRole1 && isRole2" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['../superAdmin/challan']">
                    <img src="../assets//images/icons/Challan.png" alt="Challan"
                        srcset="../assets/images/icons/ChallanW.png">
                    <p>Challan</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['../superAdmin/challan']">
                    <div class="arrowbox"></div>
                    <h6>Challan</h6>
                </div>
            </li>



            <li *ngIf="isRole3 && isRole4" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/lrmanagement']">
                    <img src="../assets/images/icons/manage-LR-32.png" alt="Manage LR"
                        srcset="../assets/images/icons/manage-LR-32.png">
                    <p>Manage LR</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/lrmanagement']">
                    <div class="arrowbox"></div>
                    <h6>Manage LR</h6>
                </div>
            </li>


            <li *ngIf="isRole2 && isRole3 && isRole4" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/usermanagement']">
                    <img src="../assets/images/icons/user-managment.png" alt="User management"
                        srcset="../assets/images/icons/user-managment.png">
                    <p>
                        Manage User
                    </p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/usermanagement']">
                    <div class="arrowbox"></div>
                    <h6>Manage User</h6>
                </div>

            </li>


            <li  *ngIf="isRole3" [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageClient']">
                    <img src="../assets/images/icons/client.png" alt="manageClient"
                        srcset="../assets/images/icons/client.png">
                    <p>Manage Company</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/manageClient']">
                    <div class="arrowbox"></div>
                    <h6>Manage Company</h6>
                </div>
            </li>



            <li *ngIf="isRole3" [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageVehicle']">
                    <img src="../assets/images/icons/manage-vehicle.png" alt="manageVehicle"
                        srcset="../assets/images/icons/manage-vehicle.png">
                    <p>Manage Vehicle</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/manageVehicle']">
                    <div class="arrowbox"></div>
                    <h6>Manage Vehicle</h6>
                </div>
            </li>



            <li *ngIf="isRole3 && isRole4" [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageDriver']">
                    <img src="../assets/images/icons/manage-driver.png" alt="manageDriver"
                        srcset="../assets/images/icons/manage-driver.png">
                    <p>Manage Driver</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/manageDriver']">
                    <div class="arrowbox"></div>
                    <h6>Manage Driver</h6>
                </div>
            </li>



            <li *ngIf="isRole2 && isRole3 && isRole4" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageExpense']">
                    <img src="../assets/images/icons/expenses.png" alt="manageExpense"
                        srcset="../assets/images/icons/expenses.png">
                    <p>Manage Cash</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/manageExpense']">
                    <div class="arrowbox"></div>
                    <h6>Manage Cash</h6>
                </div>
            </li>


            <li *ngIf="isRole3 && isRole4 && isRole1 && isRole2" [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['../superAdmin/rateChart']">
                    <img src="../assets/images/icons/rate-card-icon-b.png" alt="manageVehicle"
                        srcset="../assets/images/icons/Rate-card-white.png">
                    <p>Rate Chart</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['../superAdmin/rateChart']">
                    <div class="arrowbox"></div>
                    <h6>Rate Chart</h6>
                </div>

              
            </li>



            <li *ngIf="isRole3" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea subMenu">
                    <img src="../assets/images/icons/manage-log.png" alt="manageExpenseLog"
                        srcset="../assets/images/icons/manage-log.png">
                    <div class="d-flex align-items-center justify-content-between ">
                        <p>Miscellaneous expenses</p>
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </div>
                <div class="customedropdownmenu dashcolll dropMenu">
                    <div class="arrowbox"></div>
                    <h6>Miscellaneous expenses</h6>
                    <ul>
                        <li routerLink="../superAdmin/officeExpense/expanceList"><i
                                class="fa-solid fa-chevron-right"></i> Office Expense</li>
                        <li routerLink="../superAdmin/vehicleMaintenence/vehicleMaintenenceList"><i
                                class="fa-solid fa-chevron-right"></i> Vehicle Maintenance</li>
                                
                        <!-- <li routerLink="../superAdmin/ownVehicleExpence/ownVehicleExpenceList"><i
                                class="fa-solid fa-chevron-right"></i> Our Own Vehicle Expense</li> -->
                    </ul>
                </div>
            </li>


            <li *ngIf="isRole3 && isRole4 && isRole1 && isRole2" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']" [routerLink]="['../superAdmin/fuel']">
                <div class="menuarea">
                    <img src="../assets/images/icons/fuelB.png" alt="FuelManagement"
                        srcset="../assets/images/icons/fuelW.png">
                    <p>Fuel Management</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['../superAdmin/fuel']">
                    <div class="arrowbox"></div>
                    <h6>Fuel Management</h6>
                </div>
                <!-- <div class="customedropdownmenu dashcolll" >
                    <div class="arrowbox"></div>
                    <h6>Fuel Management</h6>
                    <ul>

                        <li ><i
                            class="fa-solid fa-chevron-right"></i>Fuel Management</li>

                            
                        <li routerLink="../superAdmin/fuel/vehicleList"><i
                            class="fa-solid fa-chevron-right"></i> Vehicle Fuel</li>
                    </ul>
                </div> -->
            </li>


            <li *ngIf="isRole3 && isRole1 && isRole2" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageShippingParties']">
                    <img src="../assets/images/icons/manageShipingB.png" alt="Manage shipping party"
                        srcset="../assets/images/icons/manageShipingW.png">
                    <p>
                        Manage Shipping Parties
                    </p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/manageshippingparties']">
                    <div class="arrowbox"></div>
                    <h6>Manage Shipping Parties</h6>
                </div>

            </li>



            <li [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/profile']">
                    <img src="../assets/images/icons/profile.svg" alt="manageClient"
                        srcset="../assets/images/icons/profile.svg">
                    <p>Profile</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/profile']">
                    <div class="arrowbox"></div>
                    <h6>Manage Profile</h6>
                </div>
            </li>



            










           


            <!-- <li *ngIf="isRole3" [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['../superAdmin/challan']">
                    <img src="../assets//images/icons/Challan.png" alt="Challan"
                        srcset="../assets/images/icons/ChallanW.png">
                    <p>Challan</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['../superAdmin/challan']">
                    <div class="arrowbox"></div>
                    <h6>Challan</h6>
                </div>
            </li> -->


            <!-- <li *ngIf="isRole3" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageShippingParties']">
                    <img src="../assets/images/icons/ManageShippingParty.png" alt="Manage shipping party"
                        srcset="../assets/images/icons/ManageShippingPartyW.png">
                    <p>
                        Manage Shipping Parties
                    </p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/manageshippingparties']">
                    <div class="arrowbox"></div>
                    <h6>Manage Shipping Parties</h6>
                </div>

            </li> -->



           



           


            

            







            <!-- <li *ngIf="isRole4" [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea subMenu">
                    <img src="../assets/images/icons/tyremanagement-black.png" alt="manageTyre"
                        srcset="../assets/images/icons/tyremanagement-white.png">
                    <div class="d-flex align-items-center justify-content-between ">
                        <p>Manage Tyre</p>
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </div> -->
                <!-- <div class="customedropdownmenu dashcolll dropMenu" *ngIf="isRole4">
                    <div class="arrowbox"></div>
                    <h6>Manage Tyre</h6>
                    <ul>
                        <li routerLink="../superAdmin/tyre-issue/tyre-issue-list"><i
                                class="fa-solid fa-chevron-right"></i> Tyre Issue/Remove</li>
                        <li routerLink="../superAdmin/tyre-issue/tyre-out-list"><i
                                class="fa-solid fa-chevron-right"></i> Tyre Out Entry</li>
                        <li routerLink="../superAdmin/tyre-issue/tyre-report-list"><i
                                    class="fa-solid fa-chevron-right"></i> Tyre Out Report</li>                       
                    </ul>
                </div>
            </li> -->
            <li *ngIf="isRole2" [routerLinkActiveOptions]="{exact:false}"[routerLinkActive]="['active']"></li>
           




           



           

           
          
            

            <!-- <li  *ngIf="isRole3" [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['../superAdmin/loadingslips']">
                    <img src="../assets/images/icons/loadingslipb.png" alt="loadingslip"
                        srcset="../assets/images/icons/loadingSlipw.png">
                    <p>Loading Slip</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['../superAdmin/loadingslips']">
                    <div class="arrowbox"></div>
                    <h6>Loading Slip</h6>
                </div>
            </li> -->

            

            <!-- <li [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']"*ngIf="isRole3 && isRole4">
                <div class="menuarea" [routerLink]="['../superAdmin/tyreinventory']">
                    <img src="../assets//images//icons/tyreInveB.png" alt="inventory-list"
                        srcset="../assets/images/icons/tyreInveW.png">
                    <p>Tyre Inventory</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['../superAdmin/tyreinventory']">
                    <div class="arrowbox"></div>
                    <h6>Tyre Inventory</h6>
                </div>
            </li> -->

            

           
            

            <!-- <div class="logout">
                <div class="logoutinn">
                    <img (click)="logout()" src="../assets/images/icons/logout.svg" alt=""
                    srcset="../assets/images/icons/logout.svg">
                    <div class="customedropdownmenu">
                        <div class="arrowbox"></div>
                        <h6>Logout</h6>
                        
                    </div>
                </div>
            </div> -->


        </ul>
        <ul>
            <li>
                <div id="dash" (click)="logout()" class="menuarea">
                    <img src="../assets/images/icons/exit.png" alt="" srcset="../assets/images/icons/exit.png">
                    <p>Logout</p>
                </div>
                <div class="customedropdownmenu dashcolll" (click)="logout()">
                    <div class="arrowbox"></div>
                    <h6>Logout</h6>
                </div>
            </li>
        </ul>



    </div>
    <div class="d-none d-xl-block">
        <div class="menuExtande open ">
            <i class="fa-solid fa-bars"></i>
        </div>
    </div>
    <!-- d-block d-sm-block d-md-none d-lg-none -->
    <!-- <div class="d-block d-sm-none d-md-block"> -->
    <div class="d-block d-sm-block d-md-none d-lg-none">
        <div class="menuExtande close ">
            <i class="fa-solid fa-xmark"></i>
        </div>
    </div>
</div>
