import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-pod-status',
  templateUrl: './pod-status.component.html',
  styleUrls: ['./pod-status.component.css']
})
export class PODStatusComponent implements OnInit {
  isFileSelcted = false;
  particlarLR: any;
  TollfilesString = [];
  TollfilesStringBack = [];
  Tollfiles: Array<File> = [];
  loadingSlipsBack: Array<File> = [];
  loadingSlipsBackString = [];
  editLR = new FormGroup({
    loadingSlipAttchment: new FormControl(""),
    loadingSlipAttchmentBack:new FormControl("",)
  })

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,public sanitanizer: DomSanitizer, public superAdminService: SuperAdminService , private toastr: ToastrService,  private router: Router) { }

  ngOnInit(): void {
    
  }

  fileChangeEventtollAttachment(fileInput: any) {
    if (fileInput.target.files.length) {
      this.Tollfiles = [];
      for (var i = 0; i < fileInput.target.files.length; i++) {
        this.Tollfiles.push(fileInput.target.files[i]);
        console.log("change event on upload file", this.Tollfiles)
      }
    }

    // this.filesToUpload.push(fileInput.target.files[0] );
  }

  addTollFiles() {
    const formData = new FormData();
    console.log("checkin lr upload kind",this.Tollfiles)

    for (let i = 0; i < this.Tollfiles.length; i++) {
      formData.append("Tollfiles[]", this.Tollfiles[i]);
    }

    this.superAdminService.addTollFiles(formData).subscribe(
      (res: {
        rawdata: any;
        status: any;
        message: any;
        authorization: any;
        code: any;
      }) => {
        if (res.status == true) {
          //alert(res.message)
          this.toastr.success(res.message);
          this.TollfilesString = res.rawdata;
          //this.router.navigate(['/superAdmin/lrmanagement']);
          //this.addNewLR.reset()
        } else {
          // alert(res.message)
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl("superAuth/login");
          } else {
            this.toastr.success(res.message);
          }
          //this.addNewLR.reset()
        }
      },
      (error) => {}
    );
  }

  updateLRSubmit(){
    debugger;
  
    console.log("PODSSSS",this.TollfilesString)
    if(this.editLR.valid){
      var form = {
        
        loadingSlipAttchment: this.TollfilesString.length
        ? this.TollfilesString[0]
        : this.particlarLR.loadingSlipAttchment,

        loadingSlipAttchmentBack: this.loadingSlipsBackString.length
        ? this.loadingSlipsBackString[0]
        : this.particlarLR.loadingSlipAttchmentBack,

        id : this.data.id

      }

      console.log("form", form)
      this.superAdminService.updatePOD({form}).subscribe(
        (res: {status: any;message: any;authorization: any;code: any}) => {
          if(res.status == true){
            this.toastr.success(res.message);
            this.reloadPage()
            this.router.navigateByUrl("/superAdmin/lrmanagement");
          }else {
            if(res.status == 401) {
              this.toastr.error(res.message)
              window.sessionStorage.clear();
              this.router.navigateByUrl("superAuth/login")
            }else{
            this.toastr.success(res.message)
            }
          }
        },
        (error) => {}

      )

    }
  }

  reloadPage(){
    window.location.reload()
  }

  fileChangeEventloadingSlipBackAttchment(fileInput: any) {
    this.loadingSlipsBack = [];
    for (var i = 0; i < fileInput.target.files.length; i++) {
      this.loadingSlipsBack.push(fileInput.target.files[i]);
    }
    console.log("back pOD",this.loadingSlipsBack)

  }

  addloadingSlipsBack() {
    const formData = new FormData();
    console.log("POD BAck ADD File",this.loadingSlipsBack)
    for (let i = 0; i < this.loadingSlipsBack.length; i++) {
      formData.append("loadingSlipsback[]", this.loadingSlipsBack[i]);
    }
    debugger;
    this.superAdminService.addloadingSlipsBack(formData)
      .subscribe((res: { rawdata: any, status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          this.toastr.success(res.message);
          this.loadingSlipsBackString = res.rawdata;
          console.log("back PODSS",this.loadingSlipsBackString)
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        

      }});


  }

}
