<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Manage Vehicle </h1>
                <button class="btn btn-dark btn-sm" mat-raised-button matTooltip="Add Vehicle" matTooltipPosition="left"
                routerLink="/superAdmin/manageVehicle/add-vehicle"><i class="fa-solid fa-plus"></i></button>
            </div>

        </div>
    </div>
    <div class="contentbox mt-3">
    <div class="row customepadding">
      <div class="col-xl-3 ms-auto">
        <input class='search-box form-control ' #data placeholder="Search"type="text" (keyup)="filterData(data.value)"/>
      </div>
        <div class="col-md-12 table-responsive ">
          
            <table mat-table [dataSource]="dataSource" matSort matSortActive="vehicle_no" matSortDirection="desc" (matSortChange)="sortData($event)" class="row-border hover tablelist" >
                        
                <ng-container matColumnDef="vehicle_no">
                    <th mat-header-cell *matHeaderCellDef> Vehicle Number </th>
                    <td mat-cell *matCellDef="let user; let i = index"> {{user.vehicle_no}} </td>
                  </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Vehicle Type </th>
                    <td mat-cell *matCellDef="let user"> {{user.type}} </td>
                  </ng-container>

                <ng-container matColumnDef="ownerName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner Name </th>
                  <td mat-cell *matCellDef="let user"> {{user.ownerName}} </td>
                </ng-container>
                
                  <ng-container matColumnDef="policyNumber">
                    <th mat-header-cell *matHeaderCellDef> Policy Number </th>
                    <td mat-cell *matCellDef="let user"> {{user.policyNumber}} </td>
                  </ng-container>

                  <ng-container matColumnDef="roadTaxDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Road Tax Date </th>
                    <td mat-cell *matCellDef="let user"> {{user.roadTaxDate | date:'yyyy-MM-dd'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="insuranceDueDate" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Insurance Due Date </th>
                    <td mat-cell *matCellDef="let user"> {{user.insuranceDueDate | date:'yyyy-MM-dd'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="fitnessDueDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fitness Due Date </th>
                    <td mat-cell *matCellDef="let user"> {{user.fitnessDueDate | date:'yyyy-MM-dd'}} </td>
                  </ng-container>
                
                  <ng-container matColumnDef="action" >
                    <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                    <td mat-cell *matCellDef="let user">
                        <div class="d-flex justify-content-center align-items-center">
                          <button [routerLink]="['/superAdmin/manageVehicle/edit-vehicle', user._id]" 
                            class="btn btn-block p-0 shadow-none"> <i matTooltip="Edit"  class="fa-solid fa-pen-to-square me-2"></i>
                          </button>
                          <button (click)="deleteuser(user._id)" class="btn btn-block p-0 shadow-none"> <i  matTooltip="Delete" class="fa fa-trash" aria-hidden="true"></i>
                          </button>
                           
                        </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
            </table>
        </div>
        <mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
       </div>
    </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>