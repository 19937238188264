import { ManageShippingPartiesModule } from './../manage-shipping-parties/manage-shipping-parties.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LrManagementRoutingModule } from './LR-management-routing.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { UsersListComponent } from './users-list/users-list.component';
// import { AddUserComponent } from './add-user/add-user.component';
// import { EditUserComponent } from './edit-user/edit-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LRListComponent } from './lr-list/lr-list.component';
import { AddLrComponent } from './add-lr/add-lr.component';
import { EditLrComponent } from './edit-lr/edit-lr.component';
import { ViewLrComponent } from './view-lr/view-lr.component';
import { sharedModule } from 'src/app/shared/myshared.module';
//import { NgxPrintModule} from 'ngx-print';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { AddInvoiceComponent } from './add-invoice/add-invoice.component';
import { DataTablesModule } from "angular-datatables";
import { DetailLrComponent } from './detail-lr/detail-lr.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { NewInvoiceComponent } from './new-invoice/new-invoice.component';
import { GstInvoiceComponent } from './gst-invoice/gst-invoice.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ImagePreviewComponent } from './image-preview/image-preview.component'
import { viewsTrackerComponent } from './views-tracker/views-tracker.component';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
import { AmountInWordsPipe } from './amount-in-words.pipe';
import { MatRadioModule } from '@angular/material/radio';
import { PODStatusComponent } from './pod-status/pod-status.component';
import { NgxPrintModule } from 'ngx-print';


@NgModule({
  // declarations: [UsersListComponent, AddUserComponent, EditUserComponent],
  declarations: [ LRListComponent, AddLrComponent, EditLrComponent, ViewLrComponent, EditInvoiceComponent, AddInvoiceComponent, DetailLrComponent, InvoiceListComponent, NewInvoiceComponent, GstInvoiceComponent, ImagePreviewComponent, viewsTrackerComponent, ViewInvoiceComponent, AmountInWordsPipe, PODStatusComponent],
  imports: [
   // NgxPrintModule,
    CommonModule,
    DataTablesModule,
    LrManagementRoutingModule,
    NgxChartsModule,
    ReactiveFormsModule,
    MatRadioModule,
    FormsModule,
    sharedModule,
    NgxPrintModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers:[],
  exports:[
    LrManagementRoutingModule
  ]
})
export class LrManagementModule { }

