import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { LightgalleryModule } from 'lightgallery/angular';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSpinnerModule,
    LightgalleryModule,
  ],
  exports: [MaterialModule, NgxSpinnerModule,LightgalleryModule],
  providers: []
})
export class sharedModule { }
