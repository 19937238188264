<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">Detail LR</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button (click)="backTo()"><i
                        class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LR </li>
                    <li class="breadcrumb-item active" aria-current="page">Detail LR</li>

                </ol>
            </nav>
        </div>

    </div>

    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="editLR">
                <div class="contentbox">
                    <div class="row account">
                       
                        <div class="col-xl-12 mb-4">
                            <div class="detailsBox">
                                <h4>Basic Details ({{ this.particlarLR?.LR_no }})</h4>
                                <div class="row">
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Manual LR No.</label>
                                        <h5>{{particlarLR.manualLR_no}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">System LR No.</label>
                                        <h5>{{particlarLR.LR_no}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Supervisor Name</label>
                                        <h5>{{particlarLR.supervisorId}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Company Name</label>
                                        <h5>{{clientName}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Consignor Name</label>
                                        <h5>{{particlarLR.consignor_name}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Consignor Address</label>
                                        <h5>{{particlarLR.consignor_address}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Consignee Name</label>
                                        <h5>{{particlarLR.consignee_name}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Consignee Address</label>
                                        <h5>{{particlarLR.consignee_address}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Vehicle No</label>
                                        <h5>{{particlarLR.vehicle_id}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Vehicle Type</label>
                                        <h5>{{particlarLR.vehicleType}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">From</label>
                                        <h5>{{particlarLR.from}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">GSTIN</label>
                                        <h5>{{particlarLR.GSTIN}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">To</label>
                                        <h5>{{particlarLR.to}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Date</label>
                                        <h5>{{particlarLR.date | date}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">E-way Bill Number</label>
                                        <h5>{{particlarLR.road_permit_no}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">State Code</label>
                                        <h5>{{particlarLR.stateCode}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">FIX / Adhoc</label>
                                        <h5>{{particlarLR.fixAdhoc}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Quantity/Weight</label>
                                        <h5>{{particlarLR.quantity}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Transporter Name</label>
                                        <h5>{{particlarLR.transportorName}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Transporter Mobile</label>
                                        <h5>{{particlarLR.transportorMobile}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Driver Name</label>
                                        <h5>{{particlarLR.driverName}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Driver Mobile</label>
                                        <h5>{{particlarLR.driverMobile}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Freight</label>
                                        <h5>{{particlarLR.Frieght}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">POD Front</label>
                                        <!-- <h5>{{particlarLR.driverName}}</h5> -->
                                        <div>
                                            <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                                                <a data-lg-size="480-475-480, 800-791-800, 1406-1390" class="gallery-item"
                                                  [attr.data-src]="apiUrl+'/'+particlarLR.loadingSlipAttchment[0]"
                                                  [attr.data-responsive]="apiUrl+'/'+particlarLR.loadingSlipAttchment[0]"
                                                  data-sub-html="<h4>Front</h4>">
                                                  <img class="img-responsive" width="70"
                                                    [src]="apiUrl+'/'+particlarLR.loadingSlipAttchment[0]" />
                                                </a>
                                                <a data-lg-size="480-480-480, 800-800-800, 1400-1400" data-pinterest-text="Shinimamiya, Osaka, Japan"
                                                  data-tweet-text="Shinimamiya, Osaka, Japan" class="gallery-item"
                                                  [attr.data-src]="apiUrl+'/'+particlarLR.loadingSlipAttchmentBack[0]"
                                                  [attr.data-responsive]="apiUrl+'/'+particlarLR.loadingSlipAttchmentBack[0]"
                                                  data-sub-html="<h4>Back ">
                                                  <img class="img-responsive"  width="70"
                                                  [src]="apiUrl+'/'+particlarLR.loadingSlipAttchmentBack[0]" />
                                                </a>
                                               
                                              </lightgallery>
                                        </div>
                                        <!-- <button class="btn btn-dark btn-sm  px-4 ms-3" type="button" (click)="previewLoadingSlip(particlarLR.loadingSlipAttchment[0], particlarLR.loadingSlipAttchmentBack[0])" mat-raised-button >Preview</button> -->
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Use Same TripID:</label>
                                        <h5>{{particlarLR.TripID}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-12 mb-4">
                            <div class="detailsBox">
                                <h4>Information For office use only</h4>
                                <div class="row">
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Starting Meter Reading</label>
                                        <h5>{{particlarLR.startmeterReading ? particlarLR.startmeterReading : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">End Meter Reading</label>
                                        <h5>{{particlarLR.endmeterReading ? particlarLR.endmeterReading:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Trip Starting Date</label>
                                        <h5>{{particlarLR.tripStartDate ? (particlarLR.tripStartDate | date):'N/A' }}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Trip End Date</label>
                                        <h5>{{particlarLR.tripEndDate ? (particlarLR.tripEndDate | date):'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Trip Starting Time</label>
                                        <h5>{{particlarLR.out_time  ? particlarLR.out_time:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Trip End Time</label>
                                        <h5>{{particlarLR.in_time ? particlarLR.in_time:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Usage KM</label>
                                        <h5>{{particlarLR.up_down_km ? particlarLR.up_down_km :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Total Hours</label>
                                        <h5>{{particlarLR.totalhour ? particlarLR.totalhour :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Extra Hours</label>
                                        <h5>{{particlarLR.extrahour ? particlarLR.extrahour :'N/A'}}</h5>
                                    </div>
                                
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Vehicle Rate</label>
                                        <h5>{{particlarLR.vehicleRate ? particlarLR.vehicleRate :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Advance</label>
                                        <h5>{{particlarLR.advance  ? particlarLR.advance :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Advance</label>
                                        <h5>{{particlarLR.advance  ? particlarLR.advance :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Balance Paid</label>
                                        <h5>{{particlarLR.balancePaid ? particlarLR.balancePaid :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Actual Balance</label>
                                        <h5>{{particlarLR.balance ? particlarLR.balance :'N/A'}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-xl-12 mb-4">
                            <div class="detailsBox">
                                <h4>Expense Details</h4>
                                <div class="row">
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Number of Tolls</label>
                                        <h5>{{particlarLR.noofTools ? particlarLR.noofTools:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Total Toll Tax Paid</label>
                                        <h5>{{particlarLR.tooltaxPaid ? particlarLR.tooltaxPaid:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Labour Loading /  Unloading</label>
                                        <h5>{{particlarLR.labourPaid ? particlarLR.labourPaid :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Other payment</label>
                                        <h5>{{particlarLR.otherPayment  ? particlarLR.otherPayment :'N/A'}}</h5>
                                    </div>
                                    
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Detention</label>
                                        <h5>{{particlarLR.detention ? particlarLR.detention :'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Diesel Expense</label>
                                        <h5>{{particlarLR.diesel_expense ? particlarLR.diesel_expense :'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Driver Cash</label>
                                        <h5>{{particlarLR.driver_cash ? particlarLR.driver_cash :'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Truck Expense</label>
                                        <h5>{{particlarLR.truck_expense ? particlarLR.truck_expense :'N/A'}}</h5>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row account mt-3 mb-3 d-none">
                    <div class="col-md-12">
                        <h4 class="text-dark"><b>Information For office use only</b></h4>
                        <hr>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Starting Meter Reading</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Enter Meter Reading" id="startmeterReadingctrl" disabled
                                            formControlName="startmeterReading">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">End Meter Reading</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Enter  Meter Reading" id="endmeterReadingctrl" disabled
                                            formControlName="endmeterReading">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Trip Starting Date</label>
                                    <div class="d-flex align-items-center">
                                        <input type="date" class="form-control me-1 shadow-none"
                                            placeholder="Enter Start Time" id="tripSDatectrl" disabled
                                            formControlName="tripSDate">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Trip End Date</label>
                                    <div class="d-flex align-items-center">
                                        <input type="date" class="form-control me-1 shadow-none"
                                            placeholder="Enter End Time" id="tripEDatectrl" disabled
                                            formControlName="tripEDate">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Trip Starting Time</label>
                                    <div class="d-flex align-items-center">
                                        <input type="time" class="form-control me-1 shadow-none"
                                            placeholder="Enter Start Time" id="outtimectrl" disabled
                                            formControlName="outtime">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Trip End Time</label>
                                    <div class="d-flex align-items-center">
                                        <input type="time" class="form-control me-1 shadow-none"
                                            placeholder="Enter End Time" id="INtimectrl" disabled
                                            formControlName="INtime">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Number of Tolls</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Enter Tolls" id="noofToolsctrl" disabled
                                            formControlName="noofTools">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Total Toll Tax Paid</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Enter Tolls Tax Paid" id="tooltaxPaidctrl" disabled
                                            formControlName="tooltaxPaid">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Labour Loading / Labour Unloading</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Labour Paid" id="labourPaidctrl" disabled
                                            formControlName="labourPaid">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Other payment</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Other payment" id="otherPaymentctrl" disabled
                                            formControlName="otherPayment">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Usage KM</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none" placeholder="KM"
                                            id="updownkmctrl" disabled formControlName="updownkm">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Total Hours</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Total Hours" id="totalhourctrl" disabled
                                            formControlName="totalhour">
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Extra Hours</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Extra Hours" id="extrahourctrl" disabled
                                            formControlName="extrahour">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Detention</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none" placeholder="Detention"
                                            id="detentionctrl" disabled formControlName="detention">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Vehicle Rate</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Vehicle Rate" id="vehicleRatectrl" disabled
                                            formControlName="vehicleRate">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Advance</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none" placeholder="Advance"
                                            disabled id="advancectrl" formControlName="advance">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Actual Balance</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Actual Balance" disabled id="balancectrl"
                                            formControlName="balance">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Balance Paid</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Balance Paid" disabled id="balancePaidctrl"
                                            formControlName="balancePaid">
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="row justify-content-center mt-2">
                            <div class="col-lg-2 col-sm-3 col-md-3 col-12 text-center">
                                <button hidden (click)="updateLRSubmit()" class="btn btn-primary text-white px-5"
                                    type="submit" mat-raised-button>Save</button>
                            </div>
                        </div>
                    </div>
                </div> -->
            </form>
        </div>
        <!-- <div class="col-xl-12">
            <div class="contentbox mt-3">
                <div class="row account">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="col-md-12 my-auto  border-bottom pb-3 border-gray mb-3 fw-bold mb-0">
                                <h4 class="fs-4 fw-bold mb-0">Invoice Details</h4>
        
                            </div>
                            <div class="col-md-3 text-end   " hidden>
                                <button  (click)="addnewinv(this.particlarLR?._id)"
                                    class="btn btn-primary text-white px-5 btn-sm">+ Add New Invoice</button>
                            </div>
                        </div>
        
                        <div class="row ">
                            <div class="col-md-12 table-responsive">
                                <table datatable class="tablelist" *ngIf='this.particlarLR?.invoice_no.length > 0'>
                                    <thead>
                                        <tr>
            
                                            <td>NO of Article</td>
                                            <td>Invoice No.</td>
                                            <td>Customer Name</td>
                                            <td>Invoice Value</td>
                                            <td>Weight</td> -->
                                            <!-- <td>Frieght</td> -->
            
                                            <!-- <td>ACTION</td>
                                        </tr>
                                    </thead>
                                    <tbody>
             -->
<!--             
                                        <tr *ngFor="let inv of this.particlarLR?.invoice_no">
                                            <td>{{ inv.NoofArticle}}</td>
                                            <td>{{ inv.InvoiceNo}}</td>
                                            <td>{{ inv.customerName}}</td>
                                            <td>{{ inv.InvoiceValue}}</td>
                                            <td>{{ inv.Weight}}</td> -->
            
                                            <!-- <td>{{ inv.Frieght}}</td> -->
                                            <!-- <td><button (click)="editInvoice(inv,this.particlarLR?._id)" class="btn btn-block"><i
                                                        matTooltip="Edit" class="fas fa-pen"></i></button></td>
                                        </tr>
            
                                    </tbody>
            
                                </table>
                            </div>
            
                        </div>
                    </div>
        
                    
                </div>
            </div>
        </div> -->
        
    </div>
</div>