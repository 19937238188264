import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddLrComponent } from './add-lr/add-lr.component';
import { DetailLrComponent } from './detail-lr/detail-lr.component';
import { EditLrComponent } from './edit-lr/edit-lr.component';
import { LRListComponent } from './lr-list/lr-list.component';
import { ViewLrComponent } from './view-lr/view-lr.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { NewInvoiceComponent } from './new-invoice/new-invoice.component';
import { GstInvoiceComponent } from './gst-invoice/gst-invoice.component';
import { viewsTrackerComponent } from './views-tracker/views-tracker.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
// import { UsersListComponent } from './users-list/users-list.component';
// import { AddUserComponent } from './add-user/add-user.component';
// import { EditUserComponent } from './edit-user/edit-user.component';

const routes: Routes = [
  {
    path: '', component: LRListComponent,
  },
  {
    path: 'add-lr/:id', component: AddLrComponent,
  },
  {
    path: 'view-tracker', component: viewsTrackerComponent,
  },
  {
    path: 'view-invoice', component: InvoiceListComponent,
  },
  {
    path: 'view-tripID', component: ViewInvoiceComponent,
  },
  {
    path: 'edit-lr/:id', component: EditLrComponent,
  }, {
    path: 'detail-lr/:id', component: DetailLrComponent,
  },
  {
    path: 'view-lr/:id', component: ViewLrComponent
  },
  {
    path: 'invoice/:id', component: NewInvoiceComponent
  },
  {
    path: 'gst-invoice/:id', component: GstInvoiceComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LrManagementRoutingModule { }
