<div class="mainebox">
<div class="leftpart">
    
</div>

<div class="container-fluid position-relative">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-center justify-content-between">
                <h1 *ngIf="dataNew != 'dialogBtn'"  class="fs-4 fw-bold">{{pageName}} </h1>
                <h1 class="fs-4 fw-bold">{{this.heading}} </h1>
                <button   *ngIf="dataNew != 'dialogBtn'" class="btn btn-dark btn-sm" matTooltip="Back" matTooltipPosition="left" mat-raised-button                       
                (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <button  *ngIf="dataNew == 'dialogBtn'" mat-dialog-close class="btn position-absolute shadow-none text-dark top-0 fs-4 end-0"><i class="fa-solid fa-xmark"></i></button>
            

        </div>
    </div>
    <div class="contentbox mt-3">
    <div class="row customepadding">
        <div class="col-md-12 table-responsive ">
            <input class='search-box' #data placeholder="search"type="text" (keyup)="filterData(data.value)"/>
            <table mat-table [dataSource]="dataSource" matSort matSortActive="vehicle_no" matSortDirection="desc" (matSortChange)="sortData($event)" class="row-border hover tablelist" >
                        
                <ng-container matColumnDef="vehicle_no">
                    <th mat-header-cell *matHeaderCellDef> Vehicle Number </th>
                    <td mat-cell *matCellDef="let user; let i = index"> {{user.vehicle_no}} </td>
                  </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Vehicle Type </th>
                    <td mat-cell *matCellDef="let user"> {{user.type}} </td>
                  </ng-container>

                <ng-container matColumnDef="ownerName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner Name </th>
                  <td mat-cell *matCellDef="let user"> {{user.ownerName}} </td>
                </ng-container>
                
                  <ng-container matColumnDef="policyNumber">
                    <th mat-header-cell *matHeaderCellDef> Policy Number </th>
                    <td mat-cell *matCellDef="let user"> {{user.policyNumber}} </td>
                  </ng-container>

                  <ng-container matColumnDef="roadTaxDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Road Tax Date </th>
                    <td mat-cell *matCellDef="let user"> {{user.roadTaxDate | date:'yyyy-MM-dd'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="insuranceDueDate" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Insurance Due Date </th>
                    <td mat-cell *matCellDef="let user"> {{user.insuranceDueDate | date:'yyyy-MM-dd'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="fitnessDueDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fitness Due Date </th>
                    <td mat-cell *matCellDef="let user"> {{user.fitnessDueDate | date:'yyyy-MM-dd'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="action" >
                    <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                    <td mat-cell *matCellDef="let user">
                        <div class="d-flex justify-content-center align-items-center">
                            <i matTooltip="Edit" [routerLink]="['/superAdmin/manageVehicle/edit-vehicle', user._id]" class="fa-solid fa-pen-to-square me-2"></i>
                            <i (click)="deleteuser(user._id)" matTooltip="Delete" class="fa fa-trash" aria-hidden="true"></i>
                        </div>
                    </td>
                  </ng-container>
                
                 
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
            </table>
        </div>
        <mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
       </div>
    </div>
</div>

</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
