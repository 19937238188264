<div class="container-fluid" [ngSwitch]="checkInvoiceType">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Invoice</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LR</li>
                    <li class="breadcrumb-item active" aria-current="page">View Invoice </li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3" >
        <div class="col-xl-12" *ngSwitchCase="'Type1'" >
            <div class="contentbox">
                <div class="text-center "  >

                    <button mat-raised-button class="btn btn-outline-dark btn-sm px-4" [useExistingCss]="true" printSectionId="print-section" >Print</button>&nbsp;
                    <button routerLink="../../gst-invoice/{{this.paramId}}" mat-raised-button class="btn btn-dark btn-sm px-4">GST Invoice</button>&nbsp;
                    <button mat-raised-button class="btn btn-dark btn-sm px-4"(click)="sendInvoice('print-section')">Email</button>
                </div>
                <div class="NewInvoice table-responsive" id="print-section">
                    <table class="newInvoiceBox">
                        <tr>
                            <td class="py-3">
                                <table class="invoiceHead">
                                    <tr>
                                        <td>Transporter Name </td>
                                        <td>: K LOGISTICS</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>Location</td>
                                        <td>: Shahdara</td>
                                    </tr>
                                    <tr>
                                        <td>Type of Vehicle </td>
                                        <td>: ECCO</td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle No</td>
                                        <td>: DL1V7320</td>
                                    </tr> -->
                                </table>
                            </td>
                            <td class="text-end py-3">
                                <img src="../../../../assets/images/invoiceLogoDemo.png" width="200" alt="">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="invoiceDetails" *ngIf='users.length > 0'>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>System LR No.</td>
                                            <td>Manual LR No.</td>
                                            <td>VEHICLE NO:</td>
                                            <td>From</td>
                                            <td>TO</td>
                                            <td>START READING</td>
                                            <td>END.READING</td>
                                            <td>KMR.</td>
                                            <td>EXTRA HRS</td>
                                            <td>Toll & Parking</td>
                                            <td>AMOUNT</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let user of users; let j=index">
                                        <tr  *ngFor="let j of user.LR_no;let i=index">
                                            <td>{{user.date[i] | date }}</td>
                                            <td>{{user.LR_no[i] }}</td>
                                            <td>{{user.manualLR_no[i] }}</td>
                                            <td>{{user.vehicle_id[i] }}</td>
                                            <td>{{user.from[i] }}</td>
                                            <td>{{user.to[i] }}</td>
                                            <td>{{user.startmeterReading[i] }}</td>
                                            <td>{{user.endmeterReading[i] }}</td>
                                            <td>{{user.up_down_km[i] }}</td>
                                            <td>{{user.extrahour[i] }} </td>
                                            <td>{{user.tooltaxPaid[i] }}</td>
                                            <td>{{user.vehicleRate[i] }}</td>
                                        </tr>
                                       
                                        <tr>
                                            <td class="footer">TOTAL</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">{{this.kmtot | number:'1.2-2'}}</td>
                                            <td class="footer">{{this.extrahour | number:'1.2-2'}}</td>
                                            <td class="footer">{{this.tooltaxPaidtot | number:'1.2-2'}}</td> 
                                            <td class="footer">{{this.invtot | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8"></td>
                                            <td colspan="3" class="p-0 ">
                                                <table class="extraText">
                                                    <tr>
                                                        <td>Total KM={{this.kmtot}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Extra Hours={{this.extrahour}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Toll & Parking={{this.tooltaxPaidtot}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total={{this.invtot| number:'1.2-2'}}</td>
                                                    </tr>
                                                </table>
                                            </td>
                
                                        </tr>
                                        <tr>
                                            <td colspan="11"><b>{{this.invtot | amountInWords}}
                                            </b></td>
                                        </tr>
                                    </tbody>
                                    
                                </table>
                                <table class="invoiceDetails" hidden>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Opening Reading </td>
                                            <td>Closing Reading</td>
                                            <td>Total KM</td>
                                            <td>Start Time</td>
                                            <td>End Time </td>
                                            <td>Total Hours</td>
                                            <td>Destination/Route</td>
                                            <td>Toll </td>
                                            <td> Parking</td>
                                            <td>Value of Total Invoices</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>&nbsp;</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>6</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>230</td>
                                            <td>&nbsp;</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>&nbsp;</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>&nbsp;</td>
                                            <td>40</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td class="footer">TOTAL</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">3997</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">65</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">2370</td>
                                            <td class="footer">1700</td>
                                            <td class="footer">2182563</td>
                                        </tr>
                                    </tbody>
                                    
                                </table>
                            </td>
                        </tr>
                        
                        <tr>
                            <td colspan="2">
                                <p>We hereby confirmed that all the LR copies duly  acknowledged by the customers have been received and above details are found correct.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="signature">
                                    <tr>
                                        <td>
                                            <p>Prepaired By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                        <td>
                                            <p>Verified By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                        <td>
                                            <p>Approved By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>


    <div class="row mt-3" *ngSwitchCase="'Type2'" >
        <div class="col-xl-12">
            <div class="contentbox">
                <div class="text-center ">

                    <button mat-raised-button class="btn btn-outline-dark btn-sm px-4" [useExistingCss]="true" printSectionId="print-section" >Print</button>&nbsp;
                    <button routerLink="../../gst-invoice/{{this.paramId}}" mat-raised-button class="btn btn-dark btn-sm px-4">GST Invoice</button>&nbsp;
                    <button mat-raised-button class="btn btn-dark btn-sm px-4"(click)="sendInvoice('print-section')">Email</button>
                </div>
                <div class="NewInvoice table-responsive" id="print-section">
                    <table class="newInvoiceBox">
                        <tr>
                            <td class="py-3">
                                <table class="invoiceHead">
                                    <tr>
                                        <td>Transporter Name </td>
                                        <td>: Whirlpool PVT LTD</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>Location</td>
                                        <td>: Shahdara</td>
                                    </tr>
                                    <tr>
                                        <td>Type of Vehicle </td>
                                        <td>: ECCO</td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle No</td>
                                        <td>: DL1V7320</td>
                                    </tr> -->
                                </table>
                            </td>
                            <td class="text-end py-3">
                                <img src="../../../../assets/images/invoiceLogoDemo.png" width="200" alt="">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="invoiceDetails" *ngIf='users.length > 0'>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>System LR No.</td>
                                            <td>Manual LR No.</td>
                                            <td>VEHICLE NO:</td>
                                            <td>From</td>
                                            <td>TO</td>
                                            <td>START READING</td>
                                            <td>END.READING</td>
                                            <td>KMR.</td>
                                            <td>EXTRA HRS</td>
                                            <td>Toll & Parking</td>
                                            <td>AMOUNT</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let user of users; let j=index">
                                        <tr  *ngFor="let j of user.LR_no;let i=index">
                                            <td>{{user.date[i] | date }}</td>
                                            <td>{{user.LR_no[i] }}</td>
                                            <td>{{user.manualLR_no[i] }}</td>
                                            <td>{{user.vehicle_id[i] }}</td>
                                            <td>{{user.from[i] }}</td>
                                            <td>{{user.to[i] }}</td>
                                            <td>{{user.startmeterReading[i] }}</td>
                                            <td>{{user.endmeterReading[i] }}</td>
                                            <td>{{user.up_down_km[i] }}</td>
                                            <td>{{user.extrahour[i] }} </td>
                                            <td>{{user.tooltaxPaid[i] }}</td>
                                            <td>{{user.vehicleRate[i] }}</td>
                                        </tr>
                                       
                                        <tr>
                                            <td class="footer">TOTAL</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">{{this.kmtot | number:'1.2-2'}}</td>
                                            <td class="footer">{{this.extrahour | number:'1.2-2'}}</td>
                                            <td class="footer">{{this.tooltaxPaidtot | number:'1.2-2'}}</td> 
                                            <td class="footer">{{this.invtot | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8"></td>
                                            <td colspan="3" class="p-0 ">
                                                <table class="extraText">
                                                    <tr>
                                                        <td>Total KM={{this.kmtot}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Extra Hours={{this.extrahour}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Toll & Parking={{this.tooltaxPaidtot}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total={{this.invtot| number:'1.2-2'}}</td>
                                                    </tr>
                                                </table>
                                            </td>
                
                                        </tr>
                                        <tr>
                                            <td colspan="11"><b>{{this.invtot | amountInWords}}
                                            </b></td>
                                        </tr>
                                    </tbody>
                                    
                                </table>
                                <table class="invoiceDetails" hidden>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Opening Reading </td>
                                            <td>Closing Reading</td>
                                            <td>Total KM</td>
                                            <td>Start Time</td>
                                            <td>End Time </td>
                                            <td>Total Hours</td>
                                            <td>Destination/Route</td>
                                            <td>Toll </td>
                                            <td> Parking</td>
                                            <td>Value of Total Invoices</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>&nbsp;</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>6</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>230</td>
                                            <td>&nbsp;</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td>01.11.2019</td>
                                            <td>165690</td>
                                            <td>165732</td>
                                            <td>42</td>
                                            <td>06.00AM</td>
                                            <td>12.00PM </td>
                                            <td>&nbsp;</td>
                                            <td>CANNAUGHT PLACE</td>
                                            <td>&nbsp;</td>
                                            <td>40</td>
                                            <td>12573</td>
                                        </tr>
                                        <tr>
                                            <td class="footer">TOTAL</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">3997</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">65</td>
                                            <td class="footer">&nbsp;</td>
                                            <td class="footer">2370</td>
                                            <td class="footer">1700</td>
                                            <td class="footer">2182563</td>
                                        </tr>
                                    </tbody>
                                    
                                </table>
                            </td>
                        </tr>
                        
                        <tr>
                            <td colspan="2">
                                <p>We hereby confirmed that all the LR copies duly  acknowledged by the customers have been received and above details are found correct.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="signature">
                                    <tr>
                                        <td>
                                            <p>Prepaired By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                        <td>
                                            <p>Verified By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                        <td>
                                            <p>Approved By</p>
                                            <p>Name</p>
                                            <p>Designation</p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>